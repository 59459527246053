import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router'
import * as Styled from './styled'
import { LANDING } from 'routes/routes'

function Logo({
  shouldNavigate,
}: {
  shouldNavigate?: boolean
}): React.ReactElement {
  const navigate = useNavigate()

  const handleNavigateToHome = (): void => {
    if (shouldNavigate) {
      navigate(LANDING)
    }
  }

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleNavigateToHome}>
      <Styled.AppLogo
        src={`https://${process.env.REACT_APP_ASSETS_BUCKET_URL}/view/img/view_logo.svg`}
        alt="logo"
      />
    </Box>
  )
}

export default Logo
