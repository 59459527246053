import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { withAuthorization } from '../Session'
import { Title } from '../Themed'
import ToastNotification from '../Notification'
import { CMS } from 'routes/routes'

import { SignupForm } from './forms'
import { useAddUser } from './hooks'

function Signup() {
  const [showToast, setShowToast] = useState(false)

  const [addUser, { loading, error, data }] = useAddUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (data?.response?.success) setShowToast(true)
  }, [data])

  return (
    <>
      <ToastNotification
        show={showToast}
        onSuspend={() => navigate(CMS)}
        headerText="SUCCESS"
        bodyText="The user has been added successfully!"
      />
      <>
        <Title>ADD NEW USER</Title>
        <SignupForm
          onSignUp={data => addUser(data)}
          loading={loading}
          error={error?.message}
        />
      </>
    </>
  )
}

export default withAuthorization(session => session && session.me)(Signup)
