import React from 'react'
import { Paper, Stack } from '@mui/material'
import { withAuthorization } from 'components/Session'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import SatelliteIcon from '@mui/icons-material/Satellite'
import ForumIcon from '@mui/icons-material/Forum'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked'
import ShieldIcon from '@mui/icons-material/Shield'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import HardwareIcon from '@mui/icons-material/Hardware'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import BusinessIcon from '@mui/icons-material/Business'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EmailIcon from '@mui/icons-material/Email'
import { Outlet } from 'react-router'
import Sidenav from 'components/Sidenav'
import * as ROUTES from 'routes/routes'

const ICON_FONT_SIZE = 22

function Dashboard(): React.ReactElement {
  const NAV_ITEMS = [
    {
      name: 'Analytics',
      icon: <AnalyticsIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.VIEW_ANALYTICS,
    },
    {
      name: 'Listings',
      icon: <SatelliteIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.SITE_UPLOAD,
    },
    {
      name: 'Conversations',
      icon: <ForumIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.CONVERSATIONS,
    },
    {
      name: 'Originations Map',
      icon: <LocationOnIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.ORIGINATIONS,
    },
    {
      name: 'Market Data',
      icon: <DatasetLinkedIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.MARKET_DATA_SERVICES,
    },
    {
      name: 'CMS',
      icon: <ShieldIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.CMS,
    },
    {
      name: 'Project Managers',
      icon: <ManageAccountsIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.MANAGERS,
    },
    {
      name: 'Clone Equipments',
      icon: <HardwareIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.EQUIPMENTS,
    },
    {
      name: 'Clone Projects',
      icon: <FlashOnIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.PROJECTS,
    },
    {
      name: 'Data Analytics',
      icon: <AnalyticsIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.ANALYTICS_SOURCE,
    },
    {
      name: 'Update Company',
      icon: <BusinessIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.COMPANIES,
    },
    {
      name: 'Usage Stats',
      icon: <TrendingUpIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.USAGE,
    },
    {
      name: 'Add User',
      icon: <PersonAddIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.SIGN_UP,
    },
    {
      name: 'Email Domains',
      icon: <EmailIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
      route: ROUTES.EMAIL_DOMAINS,
    },
  ]

  return (
    <Stack direction={`row`} sx={{ height: '100%' }}>
      <Sidenav items={NAV_ITEMS} />
      <Paper
        elevation={0}
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          p: 2,
        }}
      >
        <Outlet />
      </Paper>
    </Stack>
  )
}

export default withAuthorization((session: any) => session && session.me)(
  Dashboard
)
