export const OTHER_DELIVERY_POINT_OPTIONS = [
  {
    label: 'Busbar',
    value: 'BUSBAR',
  },
  {
    label: 'SERC Bilateral',
    value: 'SERC',
  },
  {
    label: 'WECC Bilateral',
    value: 'WECC',
  },
]

export const POWER_TRADING_HUBS_WTTH_CLEARING_CODES = [
  {
    label: 'CAISO Malin',
    value: 'CCL',
  },
  {
    label: 'CAISO NP-15',
    value: 'NPM',
  },
  {
    label: 'CAISO Palo Verde',
    value: 'PVM',
  },
  {
    label: 'CAISO SP-15',
    value: 'SPM',
  },
  {
    label: 'ERCOT Houston 345KV Hub',
    value: 'ERH',
  },
  {
    label: 'ERCOT North 345KV Hub',
    value: 'ERN',
  },
  {
    label: 'ERCOT Panhandle 345KV',
    value: 'EQP',
  },
  {
    label: 'ERCOT South 345KV Hub',
    value: 'ERS',
  },
  {
    label: 'ERCOT West 345KV Hub',
    value: 'ERW',
  },
  {
    label: 'ISO New England Connecticut',
    value: 'ICP',
  },
  {
    label: 'ISO New England Maine',
    value: 'IEB',
  },
  {
    label: 'ISO New England Massachusetts Hub',
    value: 'NEP',
  },
  {
    label: 'ISO New England NE Massachusetts',
    value: 'INB',
  },
  {
    label: 'ISO New England New Hampshire',
    value: 'IHB',
  },
  {
    label: 'ISO New England Rhode Island',
    value: 'RIY',
  },
  {
    label: 'ISO New England SE Massachusetts',
    value: 'IMB',
  },
  {
    label: 'ISO New England West Central Massachusetts',
    value: 'IWB',
  },
  {
    label: 'Mead',
    value: 'MBW',
  },
  {
    label: 'Mid-Columbia',
    value: 'MDC',
  },
  {
    label: 'MISO AMIL.BGS6',
    value: 'BGA',
  },
  {
    label: 'MISO Arkansas Hub',
    value: 'ALP',
  },
  {
    label: 'MISO Illinois Hub',
    value: 'MLB',
  },
  {
    label: 'MISO Indiana Hub',
    value: 'CIN',
  },
  {
    label: 'MISO Louisiana Hub',
    value: 'LFP',
  },
  {
    label: 'MISO Michigan Hub',
    value: 'MGP',
  },
  {
    label: 'MISO Minnesota Hub',
    value: 'MDP',
  },
  {
    label: 'MISO Texas Hub',
    value: 'TPP',
  },
  {
    label: 'NYISO Zone A',
    value: 'NAY',
  },
  {
    label: 'NYISO Zone B',
    value: 'ZBB',
  },
  {
    label: 'NYISO Zone C',
    value: 'ZCB',
  },
  {
    label: 'NYISO Zone D',
    value: 'NDR',
  },
  {
    label: 'NYISO Zone E',
    value: 'ZEB',
  },
  {
    label: 'NYISO Zone F',
    value: 'ZFB',
  },
  {
    label: 'NYISO Zone G',
    value: 'NGR',
  },
  {
    label: 'NYISO Zone I',
    value: 'ZIK',
  },
  {
    label: 'NYISO Zone J',
    value: 'NJY',
  },
  {
    label: 'NYISO Zone K',
    value: 'ZKB',
  },
  {
    label: 'PJM AECO Zone',
    value: 'PTB',
  },
  {
    label: 'PJM AEP Dayton Hub',
    value: 'MSO',
  },
  {
    label: 'PJM AEP Zone',
    value: 'PAS',
  },
  {
    label: 'PJM APS Zone',
    value: 'PUB',
  },
  {
    label: 'PJM ATSI Zone',
    value: 'PAV',
  },
  {
    label: 'PJM BGE Zone',
    value: 'BGY',
  },
  {
    label: 'PJM ComEd Resid AGG',
    value: 'COM',
  },
  {
    label: 'PJM ComEd Zone',
    value: 'CEP',
  },
  {
    label: 'PJM Day',
    value: 'DCP',
  },
  {
    label: 'PJM DEOK Zone',
    value: 'DEP',
  },
  {
    label: 'PJM DOM Zone',
    value: 'DOP',
  },
  {
    label: 'PJM DPL Zone',
    value: 'PXB',
  },
  {
    label: 'PJM Duquesne Zone',
    value: 'PQB',
  },
  {
    label: 'PJM Eastern Hub',
    value: 'PEB',
  },
  {
    label: 'PJM FE Ohio',
    value: 'PFN',
  },
  {
    label: 'PJM JCPL Zone',
    value: 'JCB',
  },
  {
    label: 'PJM METED Zone',
    value: 'PMB',
  },
  {
    label: 'PJM NI Hub',
    value: 'PNL',
  },
  {
    label: 'PJM PECO Zone',
    value: 'PCP',
  },
  {
    label: 'PJM PENELEC Zone',
    value: 'PZB',
  },
  {
    label: 'PJM PENN Power',
    value: 'PEP',
  },
  {
    label: 'PJM PEPCO MD',
    value: 'PCR',
  },
  {
    label: 'PJM PEPCO Zone',
    value: 'PPF',
  },
  {
    label: 'PJM PPL Zone',
    value: 'PPP',
  },
  {
    label: 'PJM PSEG',
    value: 'PSA',
  },
  {
    label: 'PJM SOUTH',
    value: 'SIP',
  },
  {
    label: 'PJM Western Hub',
    value: 'PJC',
  },
  {
    label: 'SPP North Hub',
    value: 'FNP',
  },
  {
    label: 'SPP South Hub',
    value: 'FSP',
  },
]

export const HUB_CLEARING_CODES = POWER_TRADING_HUBS_WTTH_CLEARING_CODES.map(
  ({ label, value }) => ({
    label: `${value} - ${label}`,
    value,
  })
)

export const SITING_TYPE_OPTIONS = [
  { value: 'PREMIER', label: 'Premier' },
  { value: 'PLUS', label: 'Plus' },
  { value: 'STANDARD', label: 'Standard' },
  { value: 'TRANSACTED', label: 'Transacted' },
]

export const AGREEMENT_TYPE_OPTIONS = [
  { value: 'LEASE', label: 'Lease' },
  { value: 'PURCHASE', label: 'Purchase' },
  { value: 'ALL', label: 'Purchase or Lease' },
]

export const SITE_TAGS_OPTIONS = [
  { value: 'HOT', label: 'Going Fast' },
  { value: 'DATACENTER', label: 'Datacenter' },
  { value: 'MA', label: 'M&A' },
  { value: 'ENERGY_COMMUNITY', label: 'Energy Community' },
]

export const TECHNOLOGY_OPTIONS = [
  { label: 'Solar', value: 'SOLAR', isFixed: true },
  { label: 'Wind', value: 'WIND' },
  { label: 'Bess', value: 'BESS' },
  { label: 'Hydrogen', value: 'HYDROGEN' },
]
