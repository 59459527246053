import React, { useState } from 'react'
import { ApolloConsumer } from '@apollo/client'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router'

import * as Styled from './styled'
import Logo from 'components/Logo'
import { SIGN_IN } from 'routes/routes'
import { Avatar, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'

function AppHeader(props: any): React.ReactElement {
  const { session } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const handleLoginClick = () => navigate(SIGN_IN)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const onLogout = (client: any): void => {
    handleClose()
    localStorage.removeItem('token')
    client.resetStore()
    navigate(SIGN_IN)
  }

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ApolloConsumer>
          {client => (
            <MenuItem onClick={() => onLogout(client)}>Logout</MenuItem>
          )}
        </ApolloConsumer>
      </Menu>
      <Styled.Header position="relative">
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.neutral.light}`,
          }}
        >
          <Logo shouldNavigate />
          {session?.me?.id ? (
            <Stack direction="row" justifyContent={'end'} width={'100%'}>
              <Stack direction="row" spacing={1}>
                <UserProfile session={session} />
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Button
              variant="contained"
              disableElevation
              onClick={handleLoginClick}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </Styled.Header>
    </>
  )
}

function UserProfile(props: any) {
  const { session } = props

  return (
    <Stack justifyContent={'center'}>
      <Tooltip
        title={
          <Stack>
            <Typography
              sx={theme => ({
                fontSize: theme.typography.caption,
              })}
            >
              {session.me.firstName} {session.me.lastName}
            </Typography>
            <Typography
              sx={theme => ({
                fontSize: theme.typography.caption,
              })}
            >
              {session.me.email}
            </Typography>
            <Typography
              sx={theme => ({
                fontSize: theme.typography.caption,
              })}
            >
              {session.me.company.name}
            </Typography>
          </Stack>
        }
      >
        <Avatar sx={{ width: 24, height: 24, fontSize: 12 }}>
          {session.me.firstName.substring(0, 1)}
        </Avatar>
      </Tooltip>
    </Stack>
  )
}

export default AppHeader
