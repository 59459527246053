const INDEX_ROUTE = '/'

export const LANDING = INDEX_ROUTE
export const DASHBOARD = '/dashboard'
export const CONVERSATIONS = `${DASHBOARD}/conversations`
export const SIGN_UP = `${DASHBOARD}/signup`
export const VIEW_ANALYTICS = `${DASHBOARD}/analytics`
export const ANALYTICS_SOURCE = `${DASHBOARD}/data`
export const CMS = `${DASHBOARD}/cms`
export const USAGE = `${DASHBOARD}/usage`
export const COMPANIES = `${DASHBOARD}/companies`
export const MANAGERS = `${DASHBOARD}/managers`
export const EQUIPMENTS = `${DASHBOARD}/equipments`
export const PROJECTS = `${DASHBOARD}/projects`
export const ORIGINATIONS = `${DASHBOARD}/originations`
export const MARKET_DATA_SERVICES = `${DASHBOARD}/mds`
export const EMAIL_DOMAINS = `${DASHBOARD}/domains`
export const PREQUALIFIED_SITES = `${DASHBOARD}/prequalified_sites`
export const SITE_UPLOAD = `${DASHBOARD}/site_upload`

export const SIGN_IN = '/signin'
