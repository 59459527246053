import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { withAuthorization } from '../Session'

import ToastNotification from '../Notification'
import Loader from '../Loader'
import Switcher from '../Switcher'
import { DataSourceForm } from './forms'

import { DROPDOWN_STYLES } from '../../constants/styles'
import { DATA_SOURCE_SWITCH_ITEMS } from './constants'

import * as hooks from './hooks'

function AreaSelect(props) {
  const { source } = props
  const [selectedArea, setSelectedArea] = useState(undefined)
  const [show, toggleToast] = useState(false)

  const { loading, error, data, refetch } = hooks.useGetDataAreas(source)
  const [updateAnalytics, { loading: updating, data: updateRes }] =
    hooks.useUpdateAnalytics(source)

  useEffect(() => {
    if (source) setSelectedArea(undefined)
  }, [source])

  useEffect(() => {
    if (updateRes?.analytics?.id) {
      toggleToast(true)
      refetch()
    }
  }, [updateRes])

  const getDropdownOptions = analyticsData =>
    analyticsData.map(area => ({
      label: source === 'us' ? area.state : area.country,
      value: area._id,
      ...area,
    }))

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { areas } = data

  return (
    <>
      <ToastNotification
        show={show}
        onSuspend={() => toggleToast(false)}
        headerText="SUCCESS!"
        bodyText="Your changes have been saved successfully."
        type="primary"
      />
      <Select
        className="mr-2 mt-4 w-25"
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={DROPDOWN_STYLES}
        options={getDropdownOptions(areas)}
        onChange={value => setSelectedArea(value)}
      />
      {selectedArea && (
        <div className="mt-4 w-25">
          <DataSourceForm
            values={selectedArea}
            onSubmit={values =>
              updateAnalytics({ id: selectedArea._id, ...values })
            }
            loading={updating}
          />
        </div>
      )}
    </>
  )
}

function AnalyticsDataSource() {
  const [sourceTab, setSourceTab] = useState('us')

  return (
    <>
      <Switcher
        switchItems={DATA_SOURCE_SWITCH_ITEMS}
        defaultValue={'us'}
        onSwitch={value => setSourceTab(value)}
      />

      <AreaSelect source={sourceTab} />
    </>
  )
}

export default withAuthorization(session => session && session.me)(
  AnalyticsDataSource
)
