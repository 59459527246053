import React, { useState } from 'react'
import { Stack, Tooltip, useTheme } from '@mui/material'
import { Link } from 'react-router'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

type NavItem = {
  name: string
  icon: React.ReactElement
  route: string
}

function Sidenav({ items }: { items: NavItem[] }): React.ReactElement {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const theme = useTheme()

  return (
    <Sidebar
      collapsed={collapsed}
      backgroundColor={'white'}
      rootStyles={{
        border: 'none !important',
        height: '100%',
      }}
    >
      <Menu
        className="vdmSideBar"
        menuItemStyles={{
          button: ({ level, active }) => {
            if (level === 0 || level === 1) {
              return {
                fontSize: 12,
                height: 40,
                color: active
                  ? theme.palette.primary.main
                  : theme.palette.neutral.dark,
                '&:hover': {
                  color: theme.palette.primary.main,
                  background: 'none',
                },
              }
            }
          },
        }}
      >
        <Stack
          sx={{
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            {items.map((item, i) => (
              <MenuItem
                active={Object.is(location.pathname, item.route)}
                key={String(i)}
                icon={
                  collapsed ? (
                    <Tooltip arrow placement="right" title={item.name}>
                      {item.icon}
                    </Tooltip>
                  ) : (
                    <>{item.icon}</>
                  )
                }
                component={<Link to={item.route} />}
              >
                {item.name}
              </MenuItem>
            ))}
          </div>
          <div>
            <MenuItem
              onClick={() => setCollapsed(!collapsed)}
              icon={
                collapsed ? (
                  <ArrowForwardIosIcon sx={{ fontSize: 22 }} />
                ) : (
                  <ArrowBackIosIcon sx={{ fontSize: 22 }} />
                )
              }
            >
              Collapse
            </MenuItem>
          </div>
        </Stack>
      </Menu>
    </Sidebar>
  )
}

export default Sidenav
