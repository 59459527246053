import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import ImageUploader from 'quill-image-uploader'
import MagicUrl from 'quill-magic-url'
import Select from 'react-select'
import { Text } from '../../Themed'
import { GET_CONVERSATION_ADMIN, Stack } from '..'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { Form, Button, Modal, Row, Col, InputGroup } from 'react-bootstrap'
import { ConversationsContext } from '..'
import withSession from '../../Session'

const Block = Quill.import('blots/block')
Block.tagName = 'div'

Quill.register(Block)
Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageUploader', ImageUploader)

export const CONVERSATION_FIELDS = gql`
  fragment ConversationFields on Conversation {
    id
    company
    site
    subject
    updatedAt
  }
`

const CREATE_CONVERSATION_ADMIN = gql`
  ${CONVERSATION_FIELDS}
  mutation CREATE_CONVERSATION_ADMIN($conversation: ConversationInput!) {
    createConversationAdmin(conversation: $conversation) {
      ...ConversationFields
    }
  }
`

const UPLOAD_NOTES_IMAGE = gql`
  mutation UPLOAD_NOTES_IMAGE($file: Upload!) {
    url: uploadNotesImage(file: $file)
  }
`

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  // ['blockquote', 'code-block'],
  ['blockquote'],
  // ['link', 'image', 'video', 'formula'],
  ['link', 'image'],

  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  // ['clean'], // remove formatting button
]

const USER_SELECT_STYLES = {
  control: baseStyles => ({
    ...baseStyles,
  }),
}

const modules = {
  toolbar: toolbarOptions,
  magicUrl: true,
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
}

const createConversationSchema = Yup.object({
  company: Yup.string().required('This is required!'),
  site: Yup.string().required('This is required!'),
  subject: Yup.string().trim().required('This is required!'),
  to: Yup.array()
    .of(Yup.string())
    .min(1, 'Atleast 1 recipient is required!')
    .required('Atleast 1 recipient is required!'),
  cc: Yup.array().of(Yup.string()),
  message: Yup.object({
    text: Yup.string().required('This is required!'),
    html: Yup.string().required('This is required!'),
  }),
  // from: Yup.string().required('This is required!'),
})

function CreateConversationForm({ onSubmit, formRef }) {
  const { companies, sites, users, landowners } =
    useContext(ConversationsContext)

  const client = useApolloClient()

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createConversationSchema),
  })

  const watchedCompany = watch('company')
  const watchedSite = watch('site')

  useEffect(() => {
    if (watchedSite) {
      const val = (
        sites.find(s => Object.is(s.id, watchedSite))?.landowners?.anchor || []
      ).map(({ id }) => id)

      setValue('to', val)
    }
  }, [watchedSite, sites, setValue])

  const companyOptions = companies.map(({ id, name }) => ({
    value: id,
    label: name,
  }))
  const siteOptions = sites.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const availableLandowners = landowners.filter(landowner => {
    if (watchedSite) {
      const site = sites.find(s => Object.is(s.id, watchedSite))

      const siteLandowners = site.landowners.all.filter(l =>
        Object.is(l.id, landowner.id)
      )

      return siteLandowners.length
    }
    return false
  })

  const availableUsers = users.filter(user => {
    if (watchedCompany) {
      return Object.is(user.company?.id, watchedCompany)
    }
    return false
  })

  const qualifiedRecipients = [...availableUsers, ...availableLandowners].map(
    recipient => ({
      value: recipient.id,
      label: `${
        recipient.name || `${recipient.firstName} ${recipient.lastName}`
      } <${recipient.email}>`,
    })
  )

  const quillModules = useMemo(
    () => ({
      ...modules,
      imageUploader: {
        upload: async file => {
          const { data } = await client.mutate({
            mutation: UPLOAD_NOTES_IMAGE,
            variables: { file },
          })

          return data.url
        },
      },
    }),
    []
  )

  // const qualifiedSenders = users
  //   .filter(user => Object.is(user.company?.id, REACT_APP_HST_COMPANY_ID))
  //   .map(user => ({
  //     value: user.id,
  //     label: `${user.firstName} ${user.lastName} <${user.email}>`,
  //   }))

  return (
    <Form
      noValidate
      onSubmit={e => {
        //To prevent the event to propagate to the conversation selector form.
        e.stopPropagation()
        return handleSubmit(onSubmit)(e)
      }}
      ref={formRef}
    >
      <Row>
        <Form.Group className="m-0 pr-1" as={Col} controlId="company">
          <Form.Label className="text-muted">Company</Form.Label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                options={companyOptions}
                onChange={option => onChange(option.value)}
              />
            )}
            name="company"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.company?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="m-0 pl-1" as={Col} controlId="site">
          <Form.Label className="text-muted">Site</Form.Label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                options={siteOptions}
                onChange={option => onChange(option.value)}
              />
            )}
            name="site"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.site?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group as={Col} className="m-0" controlId="to">
          <div className="d-flex">
            <InputGroup hasValidation style={{ flex: 0 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>To</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    styles={{ USER_SELECT_STYLES }}
                    options={qualifiedRecipients}
                    className="w-100"
                    isMulti
                    onChange={users =>
                      onChange(users.map(({ value }) => value))
                    }
                    value={qualifiedRecipients.filter(recipient =>
                      value?.includes(recipient.value)
                    )}
                  />
                )
              }}
              name="to"
              control={control}
            />
          </div>
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.to?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="m-0" controlId="cc">
          <Controller
            render={({ field: { onChange } }) => (
              <div className="d-flex">
                <InputGroup style={{ flex: 0 }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Cc</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
                <Select
                  options={qualifiedRecipients}
                  className="w-100"
                  isMulti
                  onChange={users => onChange(users.map(({ value }) => value))}
                />
              </div>
            )}
            name="cc"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.cc?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="m-0" controlId="subject">
          <Controller
            render={({ field: { onChange } }) => (
              <InputGroup hasValidation>
                <InputGroup.Prepend>
                  <InputGroup.Text id="subject-prepend">
                    Subject
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  name="subject"
                  isInvalid={!!errors.subject}
                  onChange={onChange}
                  aria-describedby="subject-prepend"
                />
              </InputGroup>
            )}
            name="subject"
            control={control}
          />
          <Form.Control.Feedback type="invalid">
            {errors.subject?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group as={Col} className="m-0" controlId="message">
          <Controller
            render={({ field: { onChange } }) => (
              <ReactQuill
                id="messageEditor"
                theme="snow"
                modules={quillModules}
                onChange={(content, _delta, _source, editor) => {
                  onChange({ html: content, text: editor.getText() })
                }}
              />
            )}
            name="message"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.message?.text?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {/* <Row> */}
      {/*   <Col> */}
      {/*     <Form.Group controlId="from"> */}
      {/*       <Form.Label className="text-muted">From</Form.Label> */}
      {/*       <Controller */}
      {/*         render={({ onChange }) => ( */}
      {/*           <Select */}
      {/*             options={qualifiedSenders} */}
      {/*             onChange={option => onChange(option.value)} */}
      {/*           /> */}
      {/*         )} */}
      {/*         name="from" */}
      {/*         control={control} */}
      {/*       /> */}
      {/*       <Form.Control.Feedback type="invalid" className="d-block"> */}
      {/*         {errors.from?.message} */}
      {/*       </Form.Control.Feedback> */}
      {/*     </Form.Group> */}
      {/*   </Col> */}
      {/* </Row> */}
    </Form>
  )
}

function CreateConversation({ session }) {
  const { users, landowners } = useContext(ConversationsContext)

  const [show, setShow] = useState(false)
  const client = useApolloClient()

  const [createConversationAdmin, { loading }] = useMutation(
    CREATE_CONVERSATION_ADMIN
  )

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const formRef = useRef(null)

  const handleThreadCreate = data => {
    const {
      company,
      site,
      subject,
      to,
      cc,
      message: { text, html },
    } = data
    createConversationAdmin({
      variables: {
        conversation: {
          company,
          site,
          subject,
          to,
          cc,
          message: {
            text,
            html,
          },
        },
      },

      update(cache, { data: { createConversationAdmin } }) {
        cache.modify({
          fields: {
            findConversationsAdmin(existingConversations = []) {
              const newConversationRef = cache.writeFragment({
                data: createConversationAdmin,
                fragment: gql`
                  fragment NewConversation on Conversation {
                    id
                    company
                    site
                    subject
                    updatedAt
                  }
                `,
              })
              return [...existingConversations, newConversationRef]
            },
          },
        })
      },
      onCompleted: async data => {
        //Write back to the messages, appending the new item
        const toUsers = [...users, ...landowners].filter(toUser =>
          to.includes(toUser.id)
        )
        const ccUsers = [...users, ...landowners].filter(ccUser =>
          cc.includes(ccUser.id)
        )
        const response = await client.query({
          query: GET_CONVERSATION_ADMIN,
          variables: { id: data.createConversationAdmin.id },
        })

        client.writeQuery({
          query: GET_CONVERSATION_ADMIN,
          variables: { id: response.data.conversationAdmin.id },
          data: {
            conversationAdmin: {
              ...response.data.conversationAdmin,
              messages: [
                ...response.data.conversationAdmin.messages,
                {
                  id: `test_id${Math.random}`,
                  to: toUsers.map(user => ({
                    email: user.email,
                    friendlyIdentifier: user.firstName
                      ? `${user.firstName} ${user.lastName} <${user.email}>`
                      : `${user.name} <${user.email}>`,
                    name: user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : `${user.name}`,
                  })),
                  cc: ccUsers.map(user => ({
                    email: user.email,
                    friendlyIdentifier: user.firstName
                      ? `${user.firstName} ${user.lastName} <${user.email}>`
                      : `${user.name} <${user.email}>`,
                    name: user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : `${user.name}`,
                  })),
                  text,
                  html,
                  reply: text,
                  sender: {
                    name: `${session?.me?.firstName} ${session?.me?.lastName}`,
                    friendlyIdentifier: `${'View Develop Marketplace'} <${`accounts@hstpowers.com`}>`,
                    email: `${`accounts@hstpowers.com`}`,
                  },
                  attachments: [],
                  messageTimeStamp: new Date(),
                },
              ],
            },
          },
        })
        handleClose()
      },
    })
  }

  const handleSubmit = (
    () => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  return (
    <React.Fragment>
      <Button
        size="sm"
        className="btn-mid"
        style={{ width: 'fit-content' }}
        onClick={handleShow}
      >
        <Stack direction="horizontal" gap={1}>
          <span className="material-icons icon-14">forum</span>
          <span>Create New</span>
        </Stack>
      </Button>
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Text size={24} weight="bold">
            Create
          </Text>
        </Modal.Header>
        <Modal.Body>
          <CreateConversationForm
            formRef={formRef}
            onSubmit={handleThreadCreate}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSubmit}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default withSession(CreateConversation)
