import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Alert } from 'react-bootstrap'
import { SignInForm } from './forms'
import withSession from '../Session'
import { useSignIn } from './hooks'
import Logo from 'components/Logo'
import { Stack, Typography, useTheme } from '@mui/material'

function NotVerifiedError() {
  return <Alert variant="danger">Email not verified. </Alert>
}

function NotActiveError() {
  return (
    <Alert variant="danger">
      Account not activated. Please contact{' '}
      <Alert.Link href="#">HST Support</Alert.Link>.
    </Alert>
  )
}

function EmailDoesNotExistError() {
  return <Alert variant="danger">Admin does not exist.</Alert>
}

function IncorrectPasswordError() {
  return <Alert variant="danger">Incorrect Password! Please try again.</Alert>
}

const ERRORS = {
  INVALID_USER: <EmailDoesNotExistError />,
  INVALID_PASSWORD: <IncorrectPasswordError />,
  NOT_VERIFIED: <NotVerifiedError />,
  INACTIVE: <NotActiveError />,
}

function SignIn(props) {
  const [networkError, setNetworkError] = useState(undefined)
  const navigate = useNavigate()
  const theme = useTheme()

  const [signIn, { loading }] = useSignIn(
    error => setNetworkError(error),
    async data => {
      localStorage.setItem('token', data.token.accessToken)
      localStorage.setItem('refreshToken', data.token.refreshToken)
      await props?.refetch()
      navigate('/')
    }
  )

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Stack
        spacing={1}
        sx={{
          border: `1px solid ${theme.palette.secondary.main}`,
          p: 4,
          borderRadius: 2,
          alignItems: 'center',
          width: 480,
        }}
      >
        <Logo />
        <Typography variant="h4" sx={{ fontWeight: 'light' }}>
          Admin Panel
        </Typography>
        {networkError &&
          networkError.message &&
          ERRORS[`${networkError.message}`]}
        <SignInForm
          onSignIn={({ email, password }) => signIn({ email, password })}
          loading={loading}
          networkError={networkError}
        />
      </Stack>
    </Stack>
  )
}

export default withSession(SignIn)
