import styled from 'styled-components'
import { Link } from 'react-router'
import { Row, Col } from 'react-bootstrap'

import * as COLORS from '../../constants/colors'

export const SidePanelContainer = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
`

export const SideTab = styled(Link)`
  margin: 4px;
  cursor: pointer;
  text-decoration: none !important;
  color: ${props => (props.active ? COLORS.PRIMARY : COLORS.GREY)} !important;
  &:hover {
    color: ${COLORS.PRIMARY} !important;
  }
  &:after {
    content: '';
    width: 4px;
    height: 24px;
    position: absolute;
    background: ${props => props.active && COLORS.PRIMARY};
    left: -4px;
  }
`

export const Panel = styled.div`
  width: 48px;
  margin-right: 30px;
`

export const ReportPanel = styled(Row)`
  height: 40px;
  padding: 16px;
`

export const ReportTab = styled(Col)`
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  display: flex;
  &:hover {
    border-bottom: 2px solid ${COLORS.PRIMARY};
  }
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid ${COLORS.PRIMARY};
    color: ${COLORS.PRIMARY} !important;
  `}
`
