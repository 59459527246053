import React, { createContext, useContext, useState } from 'react'
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Text } from '../../../../Themed'
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { NEUTRAL_2, PRIMARY_1 } from '../../../../../constants/colors'
import numeral from 'numeral'

const { REACT_APP_USER_CLIENT } = process.env

const ReadinessContext = createContext(null)

const MIN_SCORE = 0
const MAX_SCORE = 10

const DETAILS_MAP = {
  dscRatio: { label: 'Debt Service Coverage Ratio' },
  irr: { label: 'IRR(%)' },
  lcoe: { label: 'LCOE ($/MWh)' },
  ppaPrice: { label: 'PPA Price ($/MWh)' },
  iso: { label: 'Delivery Hub' },
  wgtForAvgPrice: { label: 'Weighted Avg Forward Price ($/MWh)' },
  wgtAvgLcoeSpread: {
    label: 'Weighted Avg Fwd Price - LCOE spread (%)',
    inputSuffix: '%',
  },
  ppaLcoeSpread: { label: 'PPA Price - LCOE Spread (%)', inputSuffix: '%' },
  wgtAvgPpaSpread: {
    label: 'Weighted Avg Forward Price - PPA Spread (%)',
    inputSuffix: '%',
  },
  powerProfitability: {
    label: 'Power Profitability Score (%)',
    inputSuffix: '%',
  },
  solarCapacity: { label: 'Solar Capacity (MW AC)' },
  bessCapacity: { label: 'BESS Capacity (MWh)' },
  ppaPriceBessAssumption: { label: 'PPA Price assumes payment for BESS?' },
  interconnectMaxPower: { label: 'Interconnect AC Max Power (MW)' },
  energyCommunity: { label: 'Energy Community?' },
  otherFedItcBonus: { label: 'Other Federal ITC bonus?' },
  federalITC: { label: 'Federal ITC (%)', inputSuffix: '%' },
  loanFraction: { label: 'Loan Fraction (%)', inputSuffix: '%' },
  itcAndLoanFraction: { label: 'ITC + Loan Fraction (%)', inputSuffix: '%' },
  loanInterestRate: { label: 'Loan Interest Rate (%)' },
  interconnectAndGenTieCosts: {
    label: 'Interconnect & Gen Tie Costs ($/kW AC)',
  },
  otherDevCost: {
    label: 'Other Development Costs ($/kW AC)',
  },
}

const GET_SYNCED_SCORE = gql`
  query GET_SYNCED_SCORE(
    $dscRatio: Float!
    $site: ID!
    $otherFedItcBonus: Boolean!
  ) {
    syncedScore: getSyncedScore(
      dscRatio: $dscRatio
      site: $site
      otherFedItcBonus: $otherFedItcBonus
    )
  }
`

const CORE_SCORE_FIELDS = gql`
  fragment CoreScoreFields on Score {
    id
    site
    value
    published
    subscores {
      analyze {
        value
        description
        meta
      }
      interconnect {
        value
        description
      }
      landowner {
        value
        description
      }
      permitting {
        value
        description
      }
      offtake {
        value
        description
      }
      taxCredits {
        value
        description
      }
      buildability {
        value
        description
      }
    }
  }
`

const CREATE_SCORE = gql`
  ${CORE_SCORE_FIELDS}
  mutation CREATE_SCORE($score: ScoreInput!) {
    createScore(score: $score) {
      ...CoreScoreFields
    }
  }
`

const UPDATE_SCORE = gql`
  ${CORE_SCORE_FIELDS}
  mutation UPDATE_SCORE($id: ID!, $score: ScoreInput!) {
    updateScore(id: $id, score: $score) {
      ...CoreScoreFields
    }
  }
`

const FIND_SCORES = gql`
  ${CORE_SCORE_FIELDS}
  query FIND_SCORES($filter: JSON!) {
    scores: findScores(filter: $filter) {
      ...CoreScoreFields
    }
  }
`

const scoreSchema = site =>
  Yup.object({
    analyze: Yup.object().when('project', {
      is: () => !site.project,
      then: () => Yup.object(),
      otherwise: () =>
        Yup.object({
          value: Yup.number()
            .required('This is required! Enter 5 if unsure')
            .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
            .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
            .typeError('This is required! Enter 5 if unsure'),
          description: Yup.string().trim().nullable(),
          meta: Yup.object(),
        }),
    }),
    interconnect: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    landowner: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure.')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    permitting: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure.')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    offtake: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure.')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    taxCredits: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure.')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    buildability: Yup.object({
      value: Yup.number()
        .required('This is required! Enter 5 if unsure.')
        .min(MIN_SCORE, `Should be greater than or equals ${MIN_SCORE}`)
        .max(MAX_SCORE, `Should be less than or equals ${MAX_SCORE}`)
        .typeError('This is required! Enter 5 if unsure'),
      description: Yup.string().trim().nullable(),
    }),
    published: Yup.boolean(),
  })

const syncScoreSchema = Yup.object({
  dscRatio: Yup.number()
    .required('Enter a valid value!')
    .min(0, `Should be greater than or equals 0`)
    .max(3, `Should be less than or equals 3`)
    .typeError('Enter a valid value!'),
  otherFedItcBonus: Yup.boolean(),
})

function SyncDetails({ data, disabled }) {
  const { site } = useContext(ReadinessContext)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button
        size="sm"
        className="btn-small ml-1"
        variant="outline-primary"
        disabled={disabled}
        onClick={handleShow}
      >
        Details
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-center">
            <Text size={16} weight="bold" color={PRIMARY_1} className="mr-2">
              Analyze Subscore - {numeral(data?.score).format('0.[00]')} |{' '}
              {site.name}
            </Text>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th>Item</th>
                <th>Input</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(DETAILS_MAP).map(key => (
                <tr key={key}>
                  <td>{DETAILS_MAP[key].label}</td>
                  <td>
                    {String(
                      typeof data?.[key]?.input === 'number'
                        ? numeral(data?.[key]?.input).format(
                            `0,0.[00]${DETAILS_MAP[key].inputSuffix || ''}`
                          )
                        : data?.[key]?.input === null
                        ? ''
                        : data?.[key]?.input
                    )}
                  </td>
                  <td>
                    {String(
                      typeof data?.[key]?.value === 'number'
                        ? numeral(data?.[key]?.value).format('0,0.[0]')
                        : data?.[key]?.value === null
                        ? ''
                        : data?.[key]?.value
                    )}
                  </td>
                </tr>
              ))}
              {data.outputCreatedAt && (
                <tr>
                  <td>Date of View Analyze Report</td>
                  <td>{data.outputCreatedAt.input}</td>
                  <td>
                    {new Date(data.outputCreatedAt.value).toLocaleString(
                      'en-US',
                      {
                        timeStyle: 'short',
                        dateStyle: 'short',
                      }
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

function SyncAnalyzeScoreForm({ onSync, values }) {
  const { site } = useContext(ReadinessContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(syncScoreSchema),
    defaultValues: {
      dscRatio: values?.dscRatio?.input,
      otherFedItcBonus: !!values?.otherFedItcBonus?.input,
    },
  })

  const [getSyncedScore, { loading, data: res }] =
    useLazyQuery(GET_SYNCED_SCORE)

  const onSubmit = values => {
    getSyncedScore({
      variables: {
        dscRatio: values.dscRatio,
        site: site.id,
        otherFedItcBonus: values.otherFedItcBonus,
      },
      onCompleted: data => {
        onSync(data.syncedScore)
      },
    })
  }

  return (
    <Form noValidate>
      <hr className="m-0" />
      <div className="d-flex align-items-center">
        <Text size={12} weight="bold" color={PRIMARY_1} className="mr-1">
          View Analyze Score Sync
        </Text>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              <Text
                className="lh-sm text-left"
                color="white"
                size={12}
                lines={Infinity}
              >
                Once the two inputs here are ready, click “Sync” to pull the
                data from the linked View Analyze report, and compute a subscore
                which will appear in the “View Analyze” box. Then you can click
                “Details” to see the data and calculations behind this subscore.
              </Text>
            </Tooltip>
          }
        >
          <span className="material-icons icon-primary icon-12">
            info_outline
          </span>
        </OverlayTrigger>
      </div>
      <div className="d-flex align-items-center">
        <Text size={12} color={PRIMARY_1} className="mr-1">
          Debt Service Coverage Ratio*
        </Text>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              <Text
                className="lh-sm text-left"
                color="white"
                size={12}
                lines={Infinity}
              >
                From the spreadsheet <code>hst_financial_model.xlsx </code>{' '}
                produced by the relevant View Analyze report, find the first
                year’s value for the “Debt Service Coverage Ratio” near the
                bottom of the sheet, and enter it here. It will usually be
                between 1.0 and 2.0.
              </Text>
            </Tooltip>
          }
        >
          <span className="material-icons icon-primary icon-12">
            info_outline
          </span>
        </OverlayTrigger>
      </div>
      <div className="d-flex align-items-baseline">
        <Form.Group className="m-0 w-100" controlId="dscRatio">
          <Form.Control
            {...register('dscRatio')}
            type="number"
            style={{ fontSize: 12 }}
            size="sm"
            isInvalid={!!errors.dscRatio}
          />
          <Form.Control.Feedback
            className="m-0"
            type="invalid"
            style={{ fontSize: 10 }}
          >
            {errors.dscRatio?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          size="sm"
          className="btn-small ml-1"
          variant="outline-primary"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          <div className="d-flex">
            <span>{loading ? 'Syncing' : 'Sync'}</span>
          </div>
        </Button>
        <SyncDetails
          data={res?.syncedScore || values}
          disabled={loading || !Object.keys(res?.syncedScore || values).length}
        />
      </div>
      <Form.Group controlId="otherFedItcBonus">
        <Form.Check
          {...register('otherFedItcBonus')}
          type="checkbox"
          label={
            <div className="d-flex align-items-center">
              <Text
                className="mr-1"
                size={12}
                weight="bold"
                color={PRIMARY_1}
                style={{ marginTop: 2 }}
              >
                Other Federal ITC bonus?
              </Text>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    <Text
                      className="lh-sm text-left"
                      color="white"
                      size={12}
                      lines={Infinity}
                    >
                      {' '}
                      In rare cases, the ITC used in View Analyze will be
                      something other than 30% or 40% (when incentives other
                      than the Energy Community are used). In this case, check
                      the “Other Federal ITC Bonus” box, and the calculation for
                      this item will be modified.
                    </Text>
                  </Tooltip>
                }
              >
                <span className="material-icons icon-primary icon-12">
                  info_outline
                </span>
              </OverlayTrigger>
            </div>
          }
        />
      </Form.Group>
    </Form>
  )
}

function ScoreForm({ values = {}, onScoreSubmit, loading }) {
  const { site } = useContext(ReadinessContext)
  const { analyze, ...restDefaultValues } = values

  console.log(analyze)

  const defaultValues = site.project ? values : restDefaultValues

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,

    resolver: yupResolver(scoreSchema(site)),
  })

  const handleAnalyzeScoreSync = syncedScore => {
    setValue('analyze.value', syncedScore.score, { shouldValidate: true })
    setValue('analyze.meta', syncedScore)
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onScoreSubmit)}>
      {site.project && (
        <Row className="m-0">
          <Col
            className="border rounded p-2 m-1"
            style={{ background: `${NEUTRAL_2}20` }}
          >
            <Text size={12} weight="bold" color={PRIMARY_1}>
              View Analyze
            </Text>
            <hr className="m-0" />
            <Form.Group className="m-0" controlId="analyze.value">
              <Text size={12} color={PRIMARY_1}>
                Score*
              </Text>
              <Form.Control
                {...register('analyze.value')}
                type="number"
                style={{ fontSize: 12 }}
                size="sm"
                isInvalid={!!errors.analyze?.value}
              />
              <Form.Control.Feedback
                className="m-0"
                type="invalid"
                style={{ fontSize: 10 }}
              >
                {errors.analyze?.value?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="m-0" controlId="analyze.description">
              <Text size={12} color={PRIMARY_1}>
                Description
              </Text>
              <Form.Control
                {...register('analyze.description')}
                as="textarea"
                style={{ fontSize: 12 }}
                size="sm"
                rows={2}
                isInvalid={!!errors.analyze?.description}
              />
              <Form.Control.Feedback
                className="m-0"
                type="invalid"
                style={{ fontSize: 10 }}
              >
                {errors.analyze?.description?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col
            className="border rounded p-2 m-1"
            style={{ background: `${NEUTRAL_2}20` }}
          >
            <SyncAnalyzeScoreForm
              onSync={handleAnalyzeScoreSync}
              values={values.analyze?.meta || {}}
            />
          </Col>
        </Row>
      )}
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Interconnect
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="interconnect.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('interconnect.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.interconnect?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.interconnect?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="interconnect.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('interconnect.description')}
              as="textarea"
              style={{ fontSize: 12 }}
              size="sm"
              rows={2}
              isInvalid={!!errors.interconnect?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.interconnect?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Landowner Participation
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="landowner.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('landowner.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.landowner?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.landowner?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="landowner.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('landowner.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.landowner?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.landowner?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Permitting
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="permitting.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('permitting.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.permitting?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.permitting?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="permitting.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('permitting.description')}
              as="textarea"
              rows={2}
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.permitting?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.permitting?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Offtake
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="offtake.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('offtake.value')}
              type="number"
              style={{ fontSize: 12 }}
              size="sm"
              isInvalid={!!errors.offtake?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.offtake?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="offtake.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('offtake.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.offtake?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.offtake?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Tax Credits
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="taxCredits.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('taxCredits.value')}
              size="sm"
              type="number"
              style={{ fontSize: 12 }}
              isInvalid={!!errors.taxCredits?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.taxCredits?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="taxCredits.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('taxCredits.description')}
              as="textarea"
              size="sm"
              style={{ fontSize: 12 }}
              rows={2}
              isInvalid={!!errors.taxCredits?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.taxCredits?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Text size={12} weight="bold" color={PRIMARY_1}>
            Buildability
          </Text>
          <hr className="m-0" />
          <Form.Group className="m-0" controlId="buildability.value">
            <Text size={12} color={PRIMARY_1}>
              Score*
            </Text>
            <Form.Control
              {...register('buildability.value')}
              size="sm"
              style={{ fontSize: 12 }}
              type="number"
              isInvalid={!!errors.buildability?.value}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.buildability?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="m-0" controlId="buildability.description">
            <Text size={12} color={PRIMARY_1}>
              Description
            </Text>
            <Form.Control
              {...register('buildability.description')}
              as="textarea"
              style={{ fontSize: 12 }}
              size="sm"
              rows={2}
              isInvalid={!!errors.buildability?.description}
            />
            <Form.Control.Feedback
              className="m-0"
              type="invalid"
              style={{ fontSize: 10 }}
            >
              {errors.buildability?.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="m-0">
        <Col
          className="border rounded p-2 m-1"
          style={{ background: `${NEUTRAL_2}20` }}
        >
          <Form.Group controlId="pxxEnabled">
            <Form.Check
              {...register('published')}
              type="checkbox"
              label={
                <Text
                  size={12}
                  weight="bold"
                  color={PRIMARY_1}
                  style={{ marginTop: 2 }}
                >
                  Publish?
                </Text>
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex p-1 justify-content-end w-100">
        <Button
          size="sm"
          className="btn-mid"
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  )
}

function UpdateScore({ score, onCompleted }) {
  const { site } = useContext(ReadinessContext)

  const [updateScore, { loading }] = useMutation(UPDATE_SCORE)
  const client = useApolloClient()

  const handleScoreSubmit = scores => {
    const { published, ...restScores } = scores
    const readinessScore = {
      site: site.id,
      subscores: restScores,
      published,
    }

    updateScore({
      variables: { score: readinessScore, id: score.id },
      onCompleted: data => {
        client.writeQuery({
          query: FIND_SCORES,
          variables: { filter: { site: site.id } },
          data: {
            scores: [
              {
                ...data.updateScore,
              },
            ],
          },
        })
        onCompleted()
      },
    })
  }

  return (
    <React.Fragment>
      <ScoreForm
        onScoreSubmit={handleScoreSubmit}
        loading={loading}
        values={{ ...score.subscores, published: score.published }}
      />
    </React.Fragment>
  )
}

function CreateScore({ onCompleted }) {
  const { site } = useContext(ReadinessContext)
  const [createScore, { loading }] = useMutation(CREATE_SCORE)
  const client = useApolloClient()

  const handleScoreSubmit = scores => {
    const { published, ...restScores } = scores
    const readinessScore = {
      site: site.id,
      subscores: restScores,
      published,
    }

    createScore({
      variables: { score: readinessScore },
      onCompleted: data => {
        client.writeQuery({
          query: FIND_SCORES,
          variables: { filter: { site: site.id } },
          data: {
            scores: [
              {
                ...data.createScore,
              },
            ],
          },
        })
        onCompleted()
      },
    })
  }

  return (
    <React.Fragment>
      <ScoreForm onScoreSubmit={handleScoreSubmit} loading={loading} />
    </React.Fragment>
  )
}

function ReadinessScore({ onCompleted }) {
  const { site } = useContext(ReadinessContext)
  const { loading, error, data } = useQuery(FIND_SCORES, {
    variables: { filter: { site: site.id } },
  })

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  const { scores } = data

  return (
    <React.Fragment>
      {scores.length > 0 ? (
        <React.Fragment>
          <Text size={12} color={PRIMARY_1} className="ml-1" weight="bold">
            Score - {numeral(scores[0].value).format('0.[0]')}
          </Text>
          <UpdateScore score={scores[0]} onCompleted={onCompleted} />
        </React.Fragment>
      ) : (
        <CreateScore onCompleted={onCompleted} />
      )}
    </React.Fragment>
  )
}

function Score({ site }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button
        variant="outline-primary"
        className="ml-2"
        size="sm"
        onClick={handleShow}
      >
        Readiness
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-between  w-100">
            <div className="d-flex align-items-center">
              <Text size={16} weight="bold" color={PRIMARY_1} className="mr-1">
                Readiness Score | {site.name}
              </Text>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    <Text
                      className="lh-sm text-left"
                      color="white"
                      size={12}
                      lines={Infinity}
                    >
                      Please enter a numeric value from 0 to 10 for each "Score"
                      on this form (except the automated View Analyze subscore).
                      Each “Description” should include supporting text, to be
                      seen by our customers, describing the facts that lead to
                      each subscore. If you don’t know enough to judge a given
                      subscore, enter “5”. This is the value for “no-knowledge,”
                      and also for subscores that are “at the median”. You can
                      uncheck the “Publish” box and save your changes if you are
                      awaiting some data. In that case, it may be helpful to
                      leave yourself an ALL CAPS note in the appropriate
                      “description” boxes, reminding you of which ones need
                      attention.
                    </Text>
                  </Tooltip>
                }
              >
                <span className="material-icons icon-primary icon-16">
                  info_outline
                </span>
              </OverlayTrigger>
            </div>
            {site.project && (
              <div className="d-flex">
                <span className="mr-1">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn btn-outline-primary btn-sm btn-small"
                    href={`${REACT_APP_USER_CLIENT}/analyze/projects/${site.project}/results`}
                  >
                    Project
                  </a>
                </span>
                {/* <span> */}
                {/*   <a */}
                {/*     rel="noopener noreferrer" */}
                {/*     target="_blank" */}
                {/*     className="btn btn-outline-primary btn-sm btn-small" */}
                {/*     href={`${REACT_APP_REPORTS_URL}/${site.id}/${site.submissionId}/index.html`} */}
                {/*   > */}
                {/*     Report */}
                {/*   </a> */}
                {/* </span> */}
              </div>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <ReadinessContext.Provider value={{ site }}>
            <ReadinessScore onCompleted={handleClose} />
          </ReadinessContext.Provider>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Score
