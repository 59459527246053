import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { DROPDOWN_STYLES } from '../../constants/styles'
import { withAuthorization } from '../Session'
import Loader from '../Loader'
import ToastNotification from '../Notification'
import { Text, Title } from '../Themed'
import { Users } from '../Cms'

import * as hooks from './hooks'
import { ProjectManagerForm } from './forms'
import { Box } from '@mui/material'

function Managers() {
  const [company, setCompany] = useState(undefined)
  const [invitedBy, setInvitedBy] = useState(undefined)
  const [refetch, setRefetch] = useState(false)
  const [showDeleteErr, setErr] = useState(false)

  const { loading, error, data } = hooks.useGetCompanyAdmins()
  const [
    saveProjectManager,
    { loading: registering, error: registerError, data: registerResponse },
  ] = hooks.useRegisterProjectManager()

  useEffect(() => {
    if (registerResponse?.saveProjectManager?.success) {
      setRefetch(true)
    }
  }, [registerResponse])

  useEffect(() => {
    if (registerError) {
      setErr(true)
    }
  }, [registerError])

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { admins } = data

  return (
    <>
      <Box>
        <div className="mb-4">
          <Title>EDIT PROJECT MANAGERS:</Title>
          <div>
            <Text>Select User</Text>
            <Select
              className="mr-2 mt-2 w-25"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={DROPDOWN_STYLES}
              options={admins.map(admin => ({
                label: `${admin.firstName} ${admin.lastName}`,
                value: admin,
              }))}
              onChange={({ value: { company, _id } }) => {
                setCompany(company)
                setInvitedBy(_id)
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <ProjectManagerForm
            disabled={!company}
            onFormSubmit={user => {
              const { firstName, lastName, ...rest } = user
              saveProjectManager({
                firstName: firstName,
                lastName: lastName,
                ...rest,
                company: {
                  id: company._id,
                  name: company?.name,
                  website: company?.website,
                },
                acceptTermsAndPrivacy: true,
                emailVerified: true,
                active: true,
                role: 'MANAGER',
                invitedBy,
              })
            }}
            loading={registering}
            resetForm={registerResponse?.saveProjectManager?.success}
          />
        </div>
        <div>
          <Title>LIST OF PROJECT MANAGERS:</Title>
          <Users
            editable={false}
            filter={{ company: company?._id, role: 'MANAGER' }}
            refetchMe={refetch}
            toggleRefetch={setRefetch}
          />
        </div>
      </Box>
      <ToastNotification
        show={showDeleteErr}
        headerText="ERROR!"
        bodyText={
          'The user already exists. Please use a different email address.'
        }
        onSuspend={() => setErr(false)}
        type="danger"
        delay={3000}
      />
    </>
  )
}

export default withAuthorization(session => session && session.me)(Managers)
