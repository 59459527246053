import React, { createContext } from 'react'

import Loader from '../Loader'
import { withAuthorization } from '../Session'
import { useGetPreQualifiedSites } from './hooks'
import PreQualifiedSitesMap from './Map'

export const SitesContext = createContext(null)

function Sites() {
  const { loading, error, data, refetch } = useGetPreQualifiedSites()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { preQualifiedSites = [], companies = [], landowners = [] } = data
  const splitParcels = preQualifiedSites.reduce((acc, site) => {
    return [...acc, ...site.parcels]
  }, [])

  return (
    <SitesContext.Provider value={{ companies, landowners }}>
      <PreQualifiedSitesMap
        refetch={refetch}
        sites={preQualifiedSites}
        parcels={splitParcels}
      />
    </SitesContext.Provider>
  )
}

export default withAuthorization(session => session && session.me)(Sites)
