import React from 'react'
import { Navigate, useRoutes } from 'react-router'
import Private, { Public } from 'screens'
import Landing from 'components/Landing'
import Sites from 'components/Sites'
import Originations from 'components/Originations'
import MarketDataServices from 'components/MarketDataServices'
import Cms from 'components/Cms'
import Managers from 'components/Managers'
import Equipments from 'components/Equipments'
import Projects from 'components/Projects'
import Data from 'components/Data'
import Companies from 'components/Companies'
import Usage from 'components/Usage'
import EmailDomains from 'components/EmailDomains'
import Conversations from 'components/Conversations'
import Login from 'components/Login'
import Dashboard from 'screens/Dashboard'
import * as ROUTES from 'routes/routes'
import Signup from 'components/Signup'
import NotFound from 'screens/PageNotFound'

const Routes = (props: any) => {
  return useRoutes([
    {
      path: ROUTES.LANDING,
      element: <Private {...props} />,
      children: [
        {
          element: <Navigate to={ROUTES.VIEW_ANALYTICS} replace />,
          path: ROUTES.LANDING,
        },
        {
          path: ROUTES.DASHBOARD,
          element: <Dashboard />,
          children: [
            {
              element: <Landing />,
              path: ROUTES.VIEW_ANALYTICS,
            },
            {
              element: <Sites />,
              path: ROUTES.SITE_UPLOAD,
            },
            {
              element: <Originations />,
              path: ROUTES.ORIGINATIONS,
            },
            {
              element: <MarketDataServices />,
              path: ROUTES.MARKET_DATA_SERVICES,
            },
            {
              element: <Cms />,
              path: ROUTES.CMS,
            },
            {
              element: <Signup />,
              path: ROUTES.SIGN_UP,
            },
            {
              element: <Managers />,
              path: ROUTES.MANAGERS,
            },
            {
              element: <Equipments />,
              path: ROUTES.EQUIPMENTS,
            },
            {
              element: <Projects />,
              path: ROUTES.PROJECTS,
            },
            {
              element: <Data />,
              path: ROUTES.ANALYTICS_SOURCE,
            },
            {
              element: <Companies />,
              path: ROUTES.COMPANIES,
            },
            {
              element: <Usage />,
              path: ROUTES.USAGE,
            },
            {
              element: <EmailDomains />,
              path: ROUTES.EMAIL_DOMAINS,
            },
            {
              element: <Conversations />,
              path: ROUTES.CONVERSATIONS,
            },
            { path: '*', element: <NotFound /> },
          ],
        },
      ],
    },
    {
      path: ROUTES.LANDING,
      element: <Public {...props} />,
      children: [
        { element: <Login />, path: ROUTES.SIGN_IN },
        { path: '*', element: <NotFound /> },
      ],
    },
  ])
}

export default Routes
