import React from 'react'
import { Outlet } from 'react-router'
import { Box, Stack, useTheme } from '@mui/material'
import { withAuthorization } from 'components/Session'
import AppHeader from 'components/AppHeader'

//TODO: This should be set in the withSession module. Need to add more robust
//types.
export type OutletContextType = {
  session: any
  refetch: any
}

export function Public(props: any): React.ReactElement {
  return (
    <Stack sx={{ height: '100%' }}>
      <Outlet context={{ ...props }} />
    </Stack>
  )
}

function Private(props: any): React.ReactElement {
  const theme = useTheme()

  return (
    <Stack sx={{ height: '100%' }}>
      <AppHeader {...props} />
      <Box
        component="main"
        sx={{
          flex: 1,
          maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 1px)`,
        }}
      >
        <Outlet context={{ ...props }} />
      </Box>
    </Stack>
  )
}

export default withAuthorization((session: any) => session && session.me)(
  Private
)
