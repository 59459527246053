import React from 'react'
import { withAuthorization } from '../Session'
import { Subtitle } from '../Themed'
import { ForwardCurveForm } from './forms'
import { useFetchForwardCurve } from './hooks'
import { Box } from '@mui/material'

function ForwardCurve() {
  const [fetchFwdCurve, { loading }] = useFetchForwardCurve()

  const handleFwdCurveFetch = values => {
    const { frequency, clearingCode } = values

    fetchFwdCurve(clearingCode, frequency)
  }

  return (
    <ForwardCurveForm onFormSubmit={handleFwdCurveFetch} loading={loading} />
  )
}

function MarketData() {
  return (
    <Box>
      <Subtitle className="mb-2">Forward Curve</Subtitle>
      <ForwardCurve />
    </Box>
  )
}

export default withAuthorization(session => session && session.me)(MarketData)
