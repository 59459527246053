import React from 'react'

import { withAuthorization } from '../Session'
import Loader from '../Loader'
import OriginationMap from './map'

import { useGetOriginations } from './hooks'

function Originations() {
  const { loading, error, data } = useGetOriginations()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { originations } = data

  return <OriginationMap data={originations || []} />
}

export default withAuthorization(session => session && session.me)(Originations)
