import React, { useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import moment from 'moment'
import { Chart } from 'react-google-charts'
import numeral from 'numeral'
import Select from 'react-select'
import { DROPDOWN_STYLES } from '../../constants/styles'

import { Text, Subtitle } from '../Themed'
import DateRange from '../DateRange'
import { withAuthorization } from '../Session'
import Loader from '../Loader'

import * as hooks from './hooks'
import * as COLORS from '../../constants/colors'

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env

function ProjectsAnalytics() {
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  })

  const { loading, error, data } = hooks.useGetProjectsAnalytics(
    dateRange.start.utc().toDate().toUTCString(),
    dateRange.end.utc().toDate().toUTCString()
  )

  if (loading) return <Loader />
  if (error) return <p>error..</p>

  const { projectsAnalytics } = data

  return (
    <>
      <div className="text-right mt-2">
        <DateRange
          onRangeSelect={({ start, end }) => setDateRange({ start, end })}
          range={dateRange}
        />
      </div>
      <div className="position-relative h-100">
        <Row className="mt-4 w-100 position-absolute" style={{ top: '25%' }}>
          <Col
            style={{
              borderRight: `1px solid ${COLORS.GREY_ALT}`,
            }}
          >
            <Subtitle>
              {numeral(projectsAnalytics[0]?.projectCount).format('0,0')}
            </Subtitle>
            <Text className="mt-2">PROJECTS</Text>
          </Col>
          <Col
            style={{
              borderRight: `1px solid ${COLORS.GREY_ALT}`,
            }}
          >
            <Subtitle>
              {numeral(projectsAnalytics[0]?.optimizationCount).format('0,0')}
            </Subtitle>
            <Text className="mt-2">OPTIMIZATIONS</Text>
          </Col>
          <Col>
            <Subtitle>
              {numeral(projectsAnalytics[0]?.totalSystemSizeInKw).format(0, 0)}{' '}
              KW
            </Subtitle>
            <Text className="mt-2">SYSTEM SIZE</Text>
          </Col>
        </Row>
      </div>
    </>
  )
}

function AnalyticsData(props) {
  const { selectedCompany } = props
  const { loading, data, error } = hooks.useGetCompanyAnalytics(selectedCompany)

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { projectsAnalyticsByCompany: analyticsData } = data

  return (
    <div className="position-relative h-100">
      <Row className="mt-4 w-100 position-absolute" style={{ top: '25%' }}>
        <Col
          style={{
            borderRight: `1px solid ${COLORS.GREY_ALT}`,
          }}
        >
          <Subtitle>
            {numeral(
              Math.round(analyticsData[0]?.totalSystemSizeInKw / 1000)
            ).format('0,0')}{' '}
            MW
          </Subtitle>
          <Text className="mt-2">SYSTEM SIZE</Text>
        </Col>
        <Col
          style={{
            borderRight: `1px solid ${COLORS.GREY_ALT}`,
          }}
        >
          <Subtitle>
            {numeral(analyticsData[0]?.projectCount).format('0,0')}
          </Subtitle>
          <Text className="mt-2">PROJECTS</Text>
        </Col>
        <Col
          style={{
            borderRight: `1px solid ${COLORS.GREY_ALT}`,
          }}
        >
          <Subtitle>
            {numeral(analyticsData[0]?.optimizationCount).format('0,0')}
          </Subtitle>
          <Text className="mt-2">OPTIMIZATIONS</Text>
        </Col>
        <Col>
          <Subtitle>
            {numeral(analyticsData[0]?.totalCountries?.length).format('0,0')}
          </Subtitle>
          <Text className="mt-2">COUNTRIES</Text>
        </Col>
      </Row>
    </div>
  )
}

function CompanyAnalytics() {
  const [selectedCompany, setSelectedCompany] = useState(undefined)
  const { loading, data, error } = hooks.useGetAllCompanies()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { companies } = data

  return (
    <>
      <Select
        className="mr-2 mt-2 w-50"
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={DROPDOWN_STYLES}
        defaultValue={{ label: 'All Companies', value: undefined }}
        options={[
          { label: 'All Companies', value: undefined },
          ...companies.map(company => ({
            label: company.name,
            value: company.id,
          })),
        ]}
        onChange={({ value }) => setSelectedCompany(value)}
      />
      <AnalyticsData selectedCompany={selectedCompany} />
    </>
  )
}

function CountryAnalyticsTable(props) {
  const { metrics } = props

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Capacity(MW)</th>
            <th>1st Year Production(MWh)</th>
            <th>Projects</th>
          </tr>
        </thead>
        <tbody>
          {metrics.map((row, i) => (
            <tr key={i.toString()}>
              <td>{row['_id']}</td>
              <td>
                {numeral(
                  Math.round(row['totalLastAcSystemSizeInKw'] / 1000)
                ).format(0, 0)}
              </td>
              <td>
                {numeral(
                  Math.round(row['totalFirstYearProductionInKwh'] / 1e3)
                ).format(0, 0)}
              </td>
              <td>{numeral(row['count']).format(0, 0)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

function AnalyticsMap(props) {
  const { metrics } = props

  const formatDataset = dataset => {
    const formattedData = dataset
      .filter(({ _id }) => _id !== null)
      .map(row => {
        const {
          count,
          totalFirstYearProductionInKwh,
          totalLastAcSystemSizeInKw,
          _id,
        } = row

        return [
          { v: _id, f: '' },
          { v: totalLastAcSystemSizeInKw, f: '' },
          `<div style="font-family: 'Work Sans',sans-serif !important;width: 220px">
          <p style="font-size:22px;margin:0px">
            <b style="color:${COLORS.PRIMARY_ALT}">${_id}</b>
          </p>
          <p style="font-size:14px;margin:0px;color:${COLORS.GREY}">
            <b>${count} ${count > 1 ? 'projects' : 'project'}</b>
          <p style="font-size:14px;margin:0px;color:${COLORS.GREY}">
            <b>${numeral(totalLastAcSystemSizeInKw / 1e3).format('0,0')} MW</b>
          </p>
          <p style="font-size:14px;margin:0px;color:${COLORS.GREY}">
            <b>${numeral(totalFirstYearProductionInKwh / 1e3).format(
              '0,0'
            )} MWh</b>
          </p>
          </p>
        </div>`,
        ]
      })

    return [
      [
        { role: 'tooltip', h3: { html: 'true' } },
        { role: 'tooltip', p: { html: 'true' } },
        { role: 'tooltip', p: { html: 'true' } },
      ],
      ...formattedData,
    ]
  }

  return (
    <Chart
      width={'100%'}
      height={'100%'}
      chartType="GeoChart"
      loader={<Loader light opacity={1} />}
      data={formatDataset(metrics)}
      options={{
        colorAxis: { colors: ['#c7f1ff', COLORS.PRIMARY] },
        backgroundColor: COLORS.GREY_ALT,
        legend: 'none',
        tooltip: {
          isHtml: true,
        },
      }}
      mapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
    />
  )
}

function Landing() {
  const { loading, error, data } = hooks.useGetCountryMetrics()

  if (loading) return <Loader />
  if (error) return <p>error..</p>

  const { countryMetrics } = data

  return (
    <>
      <Row className="h-50">
        <Col
          xs={6}
          style={{
            borderRight: `1px solid ${COLORS.GREY_ALT}`,
            borderBottom: `1px solid ${COLORS.GREY_ALT}`,
          }}
        >
          <AnalyticsMap metrics={countryMetrics} />
        </Col>
        <Col
          xs={6}
          style={{
            borderBottom: `1px solid ${COLORS.GREY_ALT}`,
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <CountryAnalyticsTable metrics={countryMetrics} />
        </Col>
      </Row>
      <Row className="h-50">
        <Col xs={6} style={{ borderRight: `1px solid ${COLORS.GREY_ALT}` }}>
          <ProjectsAnalytics />
        </Col>
        <Col xs={6}>
          <CompanyAnalytics />
        </Col>
      </Row>
    </>
  )
}

export default withAuthorization(session => session && session.me)(Landing)
