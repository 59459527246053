import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Global, css } from '@emotion/react'
import { setDefaultOptions } from 'esri-loader'
import * as COLORS from 'constants/colors'

import Routes from 'routes'
import withSession from 'components/Session'

//Extend the palette to include the custom 'neutral' colors.
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary']
    bg: Palette['primary']
    textColor: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
    bg: PaletteOptions['primary']
    textColor: PaletteOptions['primary']
  }
}

// configure esri-loader to lazy load the CSS
// the first time any react-arcgis components are rendered
setDefaultOptions({ css: true })

//TODO: Move typography out of this to the assignment below it.
let theme = createTheme({
  typography: {
    fontFamily: `"Be Vietnam Pro", sans-serif`,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#25274d',
          '&::before': {
            backgroundColor: '#25274d',
          },
        },
        tooltip: {
          backgroundColor: '#25274d',
          padding: 12,
        },
      },
    },
  },
})

theme = createTheme(theme, {
  mixins: {
    toolbar: {
      ...theme.mixins.toolbar,
      minHeight: 64,
    },
  },
  palette: {
    primary: {
      ...theme.palette.primary,
      main: '#67c8fa',
      dark: '#5cb4e1',
      light: '#4d506d',
      contrastText: '#25274d',
    },
    secondary: {
      ...theme.palette.secondary,
      main: '#cbd0d4',
      dark: '#b6bbbe',
      contrastText: '#6c757d',
    },
    error: {
      ...theme.palette.error,
      main: '#9f2773',
    },
    neutral: theme.palette.augmentColor({
      color: {
        main: '#cbd0d4',
        light: '#e9ecee',
        dark: '#a2a7ac',
        contrastText: '#fff',
      },
      name: 'neutral',
    }),
    bg: theme.palette.augmentColor({
      color: {
        main: '#f2f6fc',
        light: '#e9f7ff',
      },
      name: 'bg',
    }),
    textColor: theme.palette.augmentColor({
      color: {
        main: '#25274d',
        light: '#7f7f7f',
      },
      name: 'textColor',
    }),
  },
})

const globalStyles = css`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Be Vietnam Pro', sans-serif;
    height: 100%;
    min-height: 768px;
    min-width: 1366px;
  }

  #root {
    height: 100%;
  }

  .map-container {
    height: 100%;
  }

  .ps-sidebar-container {
    border-right: 1px solid #e9ecee;
  }

  .vdmSideBar {
    height: 100% !important;
  }

  .vdmSideBar > ul {
    height: 100% !important;
  }

  .ql-editor {
    font-family: 'Work Sans', sans-serif !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input,
  button {
    box-shadow: none !important;
  }

  input:focus {
    border: 1px solid ${COLORS.PRIMARY};
  }

  .form-control.is-invalid {
    background-image: none;
    padding-right: 1em;
  }

  .form-control.is-valid {
    background-image: none;
    padding-right: 1em;
  }

  .tooltip {
    font-family: 'Work Sans', sans-serif !important;
  }

  .tooltip-inner {
    max-width: 350px;
    width: inherit;
  }

  .icon-12 {
    font-size: 12px !important;
  }

  .icon-14 {
    font-size: 14px !important;
  }

  .icon-16 {
    font-size: 16px !important;
  }

  .icon-18 {
    font-size: 18px !important;
  }

  .icon-32 {
    font-size: 32px !important;
  }

  .icon-bold {
    font-weight: bold;
  }

  .icon-primary {
    color: ${COLORS.PRIMARY};
  }

  .icon-white {
    color: ${COLORS.WHITE};
  }

  .icon-primary-alt {
    color: ${COLORS.PRIMARY_ALT};
  }

  .icon-grey {
    color: ${COLORS.GREY};
  }

  .icon-danger {
    color: red;
  }

  .btn-primary {
    background-color: ${COLORS.PRIMARY} !important;
    border-color: ${COLORS.PRIMARY} !important;
  }

  .btn-outline-primary {
    color: ${COLORS.PRIMARY_ALT};
    border-color: ${COLORS.PRIMARY_ALT};
  }

  .btn-outline-primary:hover {
    color: ${COLORS.WHITE};
    border-color: ${COLORS.PRIMARY_ALT};
    background-color: ${COLORS.PRIMARY_ALT};
  }

  .btn-sm {
    padding: 0px 8px;
  }

  .btn-mid {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .btn-small {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    width: 100%;
  }

  .modal-xl {
    max-width: calc(100% - 64px);
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .modal-90w {
    width: 90vw;
    max-width: 90vw;
  }

  .form-label {
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  .invalid-feedback {
    display: block;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 500px !important;
  }

  .modal-backdrop {
    z-index: 400 !important;
  }

  .modal {
    z-index: 500 !important;
  }

  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }

  #messageEditor .ql-toolbar {
    border-radius: 6px 6px 0 0;
  }

  #messageEditor .ql-container {
    border-radius: 0 0 6px 6px;
  }

  #conv-table td {
    vertical-align: top;
  }
`

function App(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <Routes {...props} />
    </ThemeProvider>
  )
}

export default withSession(App)
