import React from 'react'
import { Button, Typography, Stack } from '@mui/material'
import { useNavigate } from 'react-router'
import { LANDING } from 'routes/routes'

function NotFound(): React.ReactElement {
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <Typography
        sx={theme => ({
          ...theme.typography.h4,
          color: theme.palette.primary.contrastText,
          fontWeight: 'light',
        })}
      >
        Page Not Found
      </Typography>
      <Typography
        sx={theme => ({
          ...theme.typography.caption,
          color: theme.palette.primary.contrastText,
          fontWeight: 'light',
        })}
      >
        The page you are looking for doesn&apos;t exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        disableElevation
        onClick={() => navigate(LANDING)}
      >
        Go to home
      </Button>
    </Stack>
  )
}

export default NotFound
