import * as Yup from 'yup'

export const companyUpdateSchema = srcApiKeyValidationFn =>
  Yup.object({
    maxProjectCount: Yup.number().typeError('Enter a valid value!'),
    maxOptimizationCount: Yup.number().typeError('Enter a valid value!'),
    maxUserCount: Yup.number().typeError('Enter a valid value!'),
    layersEnabled: Yup.boolean(),
    ultra8760Enabled: Yup.boolean(),
    merchantCurveEnabled: Yup.boolean(),
    pxxEnabled: Yup.boolean(),
    pushToSitesEnabled: Yup.boolean(),
    sitesInteractiveMapToolsEnabled: Yup.boolean(),
    srcEnabled: Yup.boolean(),
    marketplacePreviewEnabled: Yup.boolean(),
    solcastEnabled: Yup.boolean().nullable(),
    solcastDownloadEnabled: Yup.boolean().nullable(),
    cashflowEnabled: Yup.boolean(),
    additionalExclusionsEnabled: Yup.boolean(),
    sensitivityCalcEnabled: Yup.boolean(),
    enabledTabs: Yup.array(),
    license: Yup.string().typeError('Select a license type.'),
    licenseStart: Yup.date(),
    licenseEnd: Yup.date().min(
      Yup.ref('licenseStart'),
      'End date cannot be before start date.'
    ),
    dealAnalyticsTrialEnd: Yup.date(),
    srcLicense: Yup.string().typeError('Select a license type.'),
    srcApiKey: Yup.string().when(['srcLicense', 'srcEnabled'], {
      is: (srcLicense, srcEnabled) =>
        srcEnabled && Object.is(srcLicense, 'PAID'),
      then: () =>
        Yup.string()
          .trim()
          .required('API Key is required!')
          .test(
            'src-api-key-backend-validation',
            'Invalid api key',
            async srcApiKey => {
              if (srcApiKey) {
                const {
                  data: {
                    validateSrcAPiKey: { isValid },
                  },
                } = await srcApiKeyValidationFn(srcApiKey)
                return isValid
              }
            }
          ),
      otherwise: () =>
        Yup.string().trim().nullable().typeError('Enter a valid value!'),
    }),
    //TODO: Need a Solcast api validation endpoint.
    solcastApiKey: Yup.string().when('solcastEnabled', {
      is: solcastEnabled => solcastEnabled,
      then: () =>
        Yup.string()
          .trim()
          .required('Solcast Api Key is required!')
          .typeError('Enter a valid value!'),
      otherwise: () =>
        Yup.string().trim().nullable().typeError('Enter a valid value!'),
    }),
    srcLicenseEnd: Yup.date(),
    sitingClass: Yup.string().when(['enabledTabs'], {
      is: enabledTabs => enabledTabs.includes('SITING'),
      then: () =>
        Yup.string()
          .required('This is required!')
          .typeError('This is required!'),
      otherwise: () => Yup.string().nullable(),
    }),
  })
